import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './component/Navbar';

import Contact from './component/pages/Contact';
import About from './component/pages/About';
import Home from './component/pages/Home';
import Saloonreg from './component/pages/Saloonreg';
import Faq from './component/pages/Faq';
import PrivacyPolicy from './component/pages/PrivacyPolicy';
import TermandCondition from './component/pages/TermsandCondition';

import Ourservices from './component/pages/Ourservices';
import NotFound from './component/NotFound'; // Import the NotFound component

import './App.css';
import ReturnRefundPolicy from './component/pages/Refund';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/saloonreg" element={<Saloonreg />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/ourservices" element={<Ourservices />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termandcondition" element={<TermandCondition />} />
        <Route path="/refundandcancellation" element={<ReturnRefundPolicy/>} />
      
        <Route path="*" element={<NotFound/>} /> {/* Catch-all route for 404 errors */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
