import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import WarningIcon from "@mui/icons-material/Warning";

import img2 from "./image/malontext.png";
import googlePlayIcon from "./image/playstore.png";
import appStoreIcon from "./image/apple.png";

const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleIOSClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FooterContainer>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Side: Brand Logo */}
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                <img
                  src={img2}
                  alt="Brand Logo"
                  style={{ width: "120px", height: "50px", cursor: "pointer" }}
                />
              </Box>
            </Grid>

            {/* Center: Navigation Links */}
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
                <Typography variant="h6" sx={{ color: "#FFFFFF", mb: 2, fontWeight: "bold" }}>
                  Quick Links
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <FooterLink to="/aboutus">About Us</FooterLink>
                  <FooterLink to="/privacypolicy">Privacy Policy</FooterLink>
                  <FooterLink to="/termandcondition">Terms & Conditions</FooterLink>
                  <FooterLink to="/refundandcancellation">Return&refund Policy</FooterLink>
                  <FooterLink to="/contactus">Contact Us</FooterLink>
                </Box>
              </Box>
            </Grid>

            {/* Right Side: App Download, Social Media Links, and Email */}
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* App Download Buttons */}
                <Box sx={{ mb: 3, display: "flex", gap: 3 }}>
                  <Link to="https://play.google.com/apps/test/com.maloon/2" style={{ textDecoration: "none" }}>
                    <DownloadButton>
                      <img
                        src={googlePlayIcon}
                        alt="Google Play"
                        style={{ height: "36px", marginRight: "10px" }}
                      />
                      <div>
                        <Typography variant="body1" sx={{ color: "#FFFFFF", fontWeight: "500", fontSize: "0.75rem" }}>
                          GET IT ON
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#FFFFFF", fontWeight: "700", fontSize: "1.2rem" }}>
                          Google Play
                        </Typography>
                      </div>
                    </DownloadButton>
                  </Link>
                  <Link to="#" onClick={handleIOSClick} style={{ textDecoration: "none" }}>
                    <DownloadButton>
                      <img
                        src={appStoreIcon}
                        alt="App Store"
                        style={{ height: "36px", marginRight: "10px" }}
                      />
                      <div>
                        <Typography variant="body1" sx={{ color: "#FFFFFF", fontWeight: "500", fontSize: "0.70rem" }}>
                          DOWNLOAD ON THE
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#FFFFFF", fontWeight: "700", fontSize: "1.2rem" }}>
                          App Store
                        </Typography>
                      </div>
                    </DownloadButton>
                  </Link>
                </Box>

                {/* Social Media Links */}
                <Typography variant="h6" sx={{ color: "#FFFFFF", mb: 2, fontWeight: "bold" }}>
                  Follow Us
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 3 }}>
                  <IconButton
                    component="a"
                    href="https://www.instagram.com/malon.in?igsh=MXNjZWc0b2VreG56MA=="
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#FFFFFF", border: "1px solid #333", borderRadius: "50%", p: 1 }}
                  >
                    <InstagramIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                  <IconButton
                    component="a"
                    href="https://www.linkedin.com/company/malon-in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#FFFFFF", border: "1px solid #333", borderRadius: "50%", p: 1 }}
                  >
                    <LinkedInIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                  <IconButton
                    component="a"
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#FFFFFF", border: "1px solid #333", borderRadius: "50%", p: 1 }}
                  >
                    <FacebookIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </Box>

                {/* Email Section */}
                <Typography variant="h6" sx={{ color: "#FFFFFF", mb: 2, fontWeight: "bold" }}>
                  Contact Us
                </Typography>
                <Typography variant="body1" sx={{ color: "#CCCCCC", mb: 2 }}>
                  Email: <a href="mailto:contactus@malon.in" style={{ color: "#FFC53D", textDecoration: "none" }}>contactus@malon.in</a>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Bottom Copyright and Website Link */}
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderTop: "1px solid #333",
              paddingTop: "8px",
            }}
          >
            <Typography variant="body2" sx={{ color: "#EEEEEE" }}>
              &copy; {new Date().getFullYear()}{" "}
              <Link to="/" style={{ color: "#FFF", textDecoration: "none", fontWeight: "bold" }}>
                www.malon.in
              </Link>
            </Typography>
          </Box>
        </Container>
      </FooterContainer>

      {/* Modal for iOS App Coming Soon */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#1a1a1a", color: "#FFC53D" }}>
          <WarningIcon sx={{ fontSize: 50, color: "#FFC53D" }} />
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#1a1a1a", color: "#EEEEEE", textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            iOS App Coming Soon!
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            We're working hard to bring you the best experience on iOS. Stay tuned for updates!
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", backgroundColor: "#1a1a1a" }}>
          <Button onClick={handleClose} sx={{ color: "#FFC53D" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#000000", // Dark black background
  color: "#EEEEEE",
  padding: theme.spacing(6, 0),
  borderTop: "1px solid #333", // Accent color border
  boxShadow: "0px -3px 10px rgba(0, 0, 0, 0.3)",
  textAlign: "center",
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "#EEEEEE",
  textDecoration: "none",
  fontSize: "14px",
  margin: "4px 0",
  transition: "color 0.3s ease",
  "&:hover": {
    textDecoration: "underline",
    color: "#FFC53D",
  },
}));

const DownloadButton = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#000",
  borderRadius: "8px",
  padding: "10px 15px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#000",
  },
}));

export default Footer;
