import React from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  List,
  ListItem,
  Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from '../Footer';

const ReturnRefundPolicy = () => {
  const sections = [
    {
      title: 'General Policy',
      content:
        "Malon is a booking platform that connects users with salons. Each salon operates independently and provides its own services. Refunds, cancellations, and modifications are subject to the terms and conditions of each salon.",
    },
    {
      title: 'Same-Day Booking Cancellations',
      content: (
        <List>
          <ListItem>
            <strong>Full Refund:</strong> For same-day bookings, cancel within 1 hour of booking to receive a full refund.
          </ListItem>
          <ListItem>
            <strong>80% Refund:</strong> If you cancel after 1 hour of booking on the same day, 20% of the booking amount will be deducted from your refund.
          </ListItem>
        </List>
      ),
    },
    {
      title: 'Future Day Booking Cancellations',
      content: (
        <List>
          <ListItem>
            <strong>Full Refund:</strong> For bookings made for the next day or any future date, cancel within 6 hours of the booking to receive a full refund.
          </ListItem>
          <ListItem>
            <strong>Late Cancellations:</strong> Cancellations made after 6 hours may incur cancellation fees based on the salon’s specific policies.
          </ListItem>
        </List>
      ),
    },
    {
      title: 'Refunds',
      content:
        'Refunds are processed according to the above conditions. Malon assists with communication but does not issue refunds for third-party services. All refunds will be processed within 5-7 business days and credited back to the original payment method.',
    },
    {
      title: 'Rescheduling Services',
      content:
        'You may request to reschedule your appointment at least 24 hours in advance. Late requests may be subject to availability and fees.',
    },
    {
      title: 'Salon-Specific Policies',
      content:
        'Please review each salon’s return, cancellation, and refund policies before booking through Malon.',
    },
    {
      title: 'Disputes',
      content:
        'In case of a dispute, Malon’s customer support will mediate. However, final decisions are made by the salon.',
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#000', // Black background for the entire screen
          minHeight: '100vh', // Ensure the container takes full height of the screen
          py: 6,
          px: 2,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            py: 6,
            px: 4,
            backgroundColor: '#000', // Dark grey container background
            borderRadius: '16px',
            boxShadow: '0 4px 16px rgba(255, 255, 255, 0.1)', // Subtle white shadow for contrast
            color: '#fff', // White text
          }}
        >
          <Box mb={4} textAlign="center">
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#fff', // Bright accent color for the heading
                fontSize: '1.5rem',
                letterSpacing: '0.02em',
              }}
            >
              Return and Refund Policy
            </Typography>
            <Divider
              sx={{
                backgroundColor: '#FFC53D', // Same accent color for the divider
                opacity: 0.85,
                my: 2,
                height: '2px',
                width: '60px',
                mx: 'auto',
              }}
            />
          </Box>

          {sections.map((section, index) => (
            <Accordion
              key={index}
              defaultExpanded={index === 0}
              sx={{
                backgroundColor: '#121212', // Slightly lighter than the main container for contrast
                color: '#fff',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(255, 255, 255, 0.05)', // Subtle shadow for depth
                mb: 3,
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#FFC53D' }} />} // Accent color for the expand icon
                sx={{
                  backgroundColor: '#121212', // Dark grey for the accordion summary background
                  '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                  },
                  '&.Mui-expanded': {
                    backgroundColor: '#383838', // Slightly lighter when expanded
                  },
                  fontWeight: 'bold',
                  borderTopRightRadius:15,
                  borderTopLeftRadius:15,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: '600',
                    fontSize: '1rem',
                  }}
                >
                  {section.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    lineHeight: '1.7',
                    color: '#ddd', // Light grey text for better readability
                    fontSize: '0.95rem',
                  }}
                >
                  {section.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Box mt={4} textAlign="center">
            <Typography variant="body2" sx={{ color: '#ddd' }}>
              For any inquiries related to returns or refunds, please contact our customer service at{' '}
              <Link
                href="mailto:support@Malon.in"
                sx={{
                  color: '#FFC53D', // Accent color for the email link
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  '&:hover': {
                    color: '#ff80ab', // Hover effect for the link
                  },
                }}
              >
                support@Malon.in
              </Link>
              .
            </Typography>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default ReturnRefundPolicy;
